import React, { useState } from "react";
import logo from "../assets/nav.png";
import "../App.css";
import { useNavigate } from "react-router-dom";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";

const Navbar = (props) => {
  const { dropDown, setDropDown, showNav, setShowNav } = props;
  const navContents = [
    "Home",
    "Products",
    "Contacts",
    "Hosting",
    "Service Center",
  ];

  const productContent = [
    "Filecoin Miner",
    "Antminer",
    "Whatsminer",
    "KAS MIner",
    "Accessories",
  ];

  const navigate = useNavigate();

  const handlelink = (node) => {
    setShowNav(false);
    if (node === "Filecoin Miner") {
      navigate("/fileCoin");
    } else if (node === "Antminer") {
      window.open(
        " https://minersrig.com/portal/cart.php?a=confproduct&i=0"
      );
    } else if (node === "KAS MIner") {
      window.open(
        " http://minersrig.com/portal/index.php?rp=/store/kaspa-miners"
      );
    } else if (node === "Whatsminer") {
      window.open(
        "https://minersrig.com/portal/index.php?rp=/store/whats-miners"
      );
    } else {
      window.open(
        "http://minersrig.com/portal/index.php?rp=/store/accessories"
      );
    }
  };

  const handleDropdown = (node) => {
    if (node === "Products") {
      setDropDown(true);
    } else if (node === "Service Center") {
      navigate("/serviceCenter");
      setShowNav(false);
    } else if (node === "Hosting") {
      navigate("/host-miners");
      setShowNav(false);
    } else if (node === "Contacts") {
      window.open("https://minersrig.com/portal/contact.php");
    } else if (node === "DAP") {
      window.open("https://filecoin.com/");
    } else if (node === "Home") {
      navigate("/");
      setShowNav(false);
    }
  };

  const handleSign = (path) => {
    if (path === "sign") {
      window.open(
        "https://minersrig.com/portal/index.php?rp=/login"
      );
    } else if (path === "faq") {
      navigate("/faq");
      setShowNav(false);
    }
  };

  return (
    <>
      {/* desk view */}

      <div className="hidden  w-full md:flex flex-col">
        {/* top bar */}

        {/* <div className="flex justify-around  bg-[#212529] text-[#ffffff] py-3">
          <div>Free shipping, 30-day return or refund guarantee.</div>
          <div>
            <p
              className="cursor-pointer"
              onClick={() => {
                handleSign("faq");
              }}
            >
              FAQS
            </p>
          </div>
        </div> */}

        <div className="flex justify-around  text-[#212529] bg-[#ffffff] py-8">
          <img
            src={logo}
            alt="logo"
            className="md:w-[200px] h-[30px] lg:w-[255px] lg:h-[46px]"
          />
          <div className="flex lg:gap-10 md:gap-5 items-center cursor-pointer">
            {navContents.map((item) => {
              return item === "Products" ? (
                <div
                  className="flex items-center relative "
                  onMouseEnter={() => {
                    setDropDown(true);
                  }}
                >
                  <a href="#" className=" item font-semibold text-[18px]">
                    {item}
                  </a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    className={`stroke-secondary fill-secondary scale-[65%] ${
                      dropDown
                        ? "rotate-180 duration-300"
                        : "rotate-0 duration-300"
                    }`}
                  >
                    <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z" />
                  </svg>
                  {dropDown && (
                    <div
                      onMouseLeave={() => setDropDown(false)}
                      data-aos="fade-up"
                      className="absolute top-[150%] left-0 bg-[#212529] text-[#ffffff] rounded-[10px] -translate-y-0 z-50 "
                    >
                      <ul className="list-none w-max">
                        {productContent.map((node) => {
                          return (
                            <li className="text-primary px-4 py-3">
                              <a
                                onClick={() => {
                                  handlelink(node);
                                }}
                                rel="noreferrer"
                                target="_blank"
                              >
                                {node}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              ) : (
                <a
                  className=" item font-semibold text-[18px]"
                  onClick={() => {
                    handleDropdown(item);
                  }}
                >
                  {item}
                </a>
              );
            })}
          </div>
          <div className="flex lg:gap-6 md:gap-3 items-start">
        
            <button
              data-aos="flip-left"
              onClick={() => handleSign("sign")}
              className="w-fit px-3  py-1 text-black bg-slate-50 border-[1px] rounded-3xl border-black hover:bg-black hover:text-white"
            >
              Client Login
            </button>
          </div>
        </div>
      </div>

      {/* mobile view */}
      <div className="w-full flex justify-around py-4 md:hidden">
        <img src={logo} alt="logo" />
        <div
          className="rotate-90 border-2 border-black p-1 cursor-pointer"
          onClick={() => {
            setShowNav(!showNav);
          }}
        >
          <ViewWeekIcon fontSize="large" />
        </div>
      </div>
      <div
        onClick={(e) => e.stopPropagation()}
        className={`${
          showNav ? "translate-x-0" : "-translate-x-[100%]"
        } h-screen w-[80%] duration-500 bg-white md:hidden fixed z-[9999] flex flex-col items-start p-6 top-0 gap-6 pt-[20%]`}
      >
        <div className="flex flex-row justify-around items-center mx-auto gap-4">
          <p
            className="cursor-pointer"
            onClick={() => {
              handleSign("faq");
            }}
          >
            Client Login
          </p>
        </div>

  
        <div className="flex-col flex primaryFont font-bold text-xl gap-2 ">
          {navContents.map((item) => {
            return item === "Products" ? (
              <div className="flex  flex-col ">
                <div
                  className="flex items-center"
                  onClick={() => {
                    setDropDown(!dropDown);
                  }}
                >
                  <a href="#" className="font-semibold text-[18px]">
                    {item}
                  </a>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    className={`stroke-secondary fill-secondary scale-[65%] ${
                      dropDown
                        ? "rotate-180 duration-300"
                        : "rotate-0 duration-300"
                    }`}
                  >
                    <path d="M12 17.414 3.293 8.707l1.414-1.414L12 14.586l7.293-7.293 1.414 1.414L12 17.414z" />
                  </svg>
                </div>
                {dropDown && (
                  <ul className="list-none w-max">
                    {productContent.map((node) => {
                      return (
                        <li className="font-normal text-[18px] mt-2">
                          <a
                            onClick={() => {
                              handlelink(node);
                            }}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {node}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            ) : (
              <a
                className=" font-semibold text-[18px] "
                onClick={() => {
                  handleDropdown(item);
                }}
              >
                {item}
              </a>
            );
          })}
        </div>
        {/* <div>Free shipping, 30-day return or refund guarantee.</div> */}
      </div>
    </>
  );
};

export default Navbar;
