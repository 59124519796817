import React, { useEffect, useState } from "react";
import banner1 from "../assets/banner4.png";
import banner2 from "../assets/banners3.png";
import banner3 from "../assets/iceriver-banner/iceriverbanner.png";
import banner4 from "../assets/banners1.png";
import smallBanner1 from "../assets/smallBanner1.png";
import smallBanner2 from "../assets/smallBanner2.png";
import smallBanner3 from "../assets/ks0smallnanner.png";
import smallBanner4 from "../assets/smallBanner4.png";
import EastIcon from "@mui/icons-material/East";
import "../App.css";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();
  const slideData = [
    
    {
      image: window.innerWidth < 768 ? smallBanner2 : banner2,
      Header: "Antiminer",
      subHeader: " S19j pro+ 120T",
      title: "Miners Rig",
      content:
      " The Antminer S19j Pro+ from Bitmain is designed for mining the SHA-256 algorithm, boasting a maximum hashrate of 120Th/s with a power consumption of 3355W. This mining rig is capable of mining Bitcoin (BTC) and other SHA-256 protocol coins such as BSV, BCH, and LTC. The device comes equipped with an attached power supply.",
    },
    {
      image: window.innerWidth < 768 ? smallBanner3 : banner3,
      Header: "Iceriver",
      subHeader: " KS0",
      title: "KS0 (100GH) from IceRiver",
      content:
      " The KS0 from IceRiver is a compact home miner featuring a 100 GH/s Kaspa mining hashrate and a low power consumption of only 65W. Its efficiency makes it an excellent choice for placement in any living room or office space.",
    },
    {
      image: window.innerWidth < 768 ? smallBanner4 : banner4,
      Header: "Whatsminer",
      subHeader: " MicroBT M50s 120T ",
      title: "Miners Rig",
      content:
      "The M50 is characterized by a compact and space-efficient design in comparison to certain other mining machines. With advanced 16nm chips and a high-efficiency power supply, the M50 optimizes mining power while keeping energy usage to a minimum. Additionally, the M50 provides remote management capabilities through a web interface, allowing for convenient monitoring and control from any location.",
    },
    {
      image: window.innerWidth < 768 ? smallBanner1 : banner1,
      Header: "Filecoin",
      subHeader: " Miner",
      title: "",
      content:
        " Explore the revolution of decentralized storage through FILECOIN MINING, empowering individuals and organizations to engage in a secure, efficient global storage network. By minimizing risks with MINERSRIG, participants can earn rewards for their contributions to this decentralized storage ecosystem.",
    },
  ];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % slideData.length);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [slideData.length]);

  const handleSlider = (ind) => {
    if (ind === slideData.length - 1) {
      setIndex(0);
    } else {
      setIndex((ind) => ind + 1);
    }
  };

  const handlelink = (node) => {
    if (node === "Filecoin") {
      navigate("/fileCoin");
    } else if (node === "Antiminer") {
      window.open(
        " http://minersrig.com/portal/index.php?rp=/store/bitmain-asic-miners"
      );
    } else if (node === "Iceriver") {
      window.open(
        " http://minersrig.com/portal/index.php?rp=/store/kaspa-miners"
      );
    } else if (node === "Whatsminer") {
      window.open(
        "https://minersrig.com/portal/index.php?rp=/store/whats-miners"
      );
    }
  };
  console.log(index, "index");

  return (
    <div className="relative">
      <img
        src={slideData[index]?.image}
        alt="banner1"
        className=" w-screen lg:h-[80vh] md:h-[60vh] h-auto"
        onClick={() => {
          handleSlider(index);
        }}
      />
      {/* desk view */}

      <div className=" hidden absolute bottom-[20%] lg:mx-10 md:mx-5 md:flex flex-col bg-black items-start lg:gap-10 md:gap-5 lg:p-10 md:p-5 text-white ">
        <p
          data-aos="fade-up"
          className="lg:font-bold lg:text-2xl text-base md:text-xl font-medium"
        >
          <span className="lg:text-5xl lg:font-black md:text-2xl md:font-bold">
            {slideData[index]?.Header}
          </span>{" "}
          <br />
          {slideData[index]?.subHeader}
        </p>
        <p
          data-aos="fade-up"
          className="md:w-[40vw] lg:w-[30vw] text-justify lg:text-xl md:text-base"
        >
          <span>{slideData[index]?.title}</span> {slideData[index]?.content}
        </p>
        <button
          className="bg-[#FFFFFF]  hover:bg-yellow-500 w-fit lg:px-10 lg:py-4 lg:font-bold md:px-6 md:py-2 md:font-medium font-nunito md: text-black flex items-center"
          onClick={() => handlelink(slideData[index]?.Header)}
        >
          {" "}
          SHOP NOW
          <span className="ml-2">
            <EastIcon />
          </span>
        </button>
      </div>

      {/* mobile view */}

      <div className=" md:hidden absolute flex flex-col bg-black  text-white gap-4 items-start justify-center top-0 bottom-[50%] w-full px-10">
        <p data-aos="fade-up" className=" text-base font-medium">
          <span className=" font-semibold text-xl">
            {slideData[index]?.Header}
          </span>{" "}
          <br />
          {slideData[index]?.subHeader}
        </p>
        <p data-aos="fade-up" className=" text-justify">
          <span>{slideData[index]?.title}</span> {slideData[index]?.content}
        </p>
        <button
          className="bg-[#FFFFFF] hover:bg-yellow-200  w-fit font-nunito flex items-center text-black p-3"
          onClick={() => handlelink(slideData[index]?.Header)}
        >
          {" "}
          SHOP NOW
          <span className="ml-2">
            <EastIcon />
          </span>
        </button>
      </div>
    </div>
  );
};

export default Banner;
