import React, { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";

const QA = (props) => {
  const { ques, ans } = props;
  const [expand, setExpand] = useState(false);

  const handleAcco = () => {
    setExpand(!expand);
  };

  return (
    <div className="flex flex-col w-[70vw] md:w-[60vw] gap-3 py-6 border-b-[1px] border-black">
      <div className="flex justify-between items-center ">
        <p className="text-2xl font-normal">{ques}</p>
        {expand ? (
          <div onClick={() => handleAcco()}>
            <RemoveCircleOutlineIcon fontSize="large" />
          </div>
        ) : (
          <div onClick={() => handleAcco()}>
            {" "}
            <ControlPointRoundedIcon fontSize="large" />
          </div>
        )}
      </div>
      {expand && <p className="text-lg font-light">{ans}</p>}
    </div>
  );
};

export default QA;
