import React, { useEffect, useState } from "react";
import bs1 from "../assets/filecoin-pic.png";
import bs2 from "../assets/Asic Whatsminer m30s 90th-2.png";
import bs3 from "../assets/Asic Bitmain Antminer KA3 166th_3.png";
import bs4 from "../assets/Asic Bitmain Antminer T19 hydro 145th_3.png";
import hs1 from "../assets/hot sales/Asic Bitmain Antminer S19 XP hydro 250th_5.png";
import hs2 from "../assets/hot sales/ICERIVER KAS KS0 3.png";
import hs3 from "../assets/hot sales/ICERIVER KAS KS3M 4.png";
import hs4 from "../assets/hot sales/JASMINER X16P 5800MH1900W-1.png";
import na1 from "../assets/new arrivals/Asic Bitmain Antminer Z15 420ksol_3.png";
import na2 from "../assets/new arrivals/Asic Whatsminer m30s 90th-3.png";
import na3 from "../assets/new arrivals/ICERIVER KAS KS1 3.png";
import na4 from "../assets/new arrivals/JASMINER X16Q 1950MH620W-2.png";
import Cards from "./Cards";
import { useNavigate } from "react-router-dom";

const BestSeller = () => {
  const [selected, setSelected] = useState("Best Sellers");
  const [displayArray, setDisplayArray] = useState([]);

  useEffect(() => {
    setDisplayArray(BestSellersArray);
  }, []);

  const BestSellersArray = [
    {
      image: bs1,
      name: "FileCoin Miner",
      price: "Price on Request",
      Desc: "https://minersrig.com/portal/index.php?rp=/store/filecoin-miners",
    },
    {
      image: bs2,
      name: "Asic Whatsminer M50s 120T",
      price: "Price on Request",
      desc: "https://minersrig.com/portal/cart.php?a=confproduct&i=9",
    },
    {
      image: bs3,
      name: "Asic Whatsminer M30s 90T",
      price: "Price on Request",
      Desc: "http://minersrig.com/portal/index.php?rp=/store/whats-miners/whatsminer-m30s90th-s",
    },
    {
      image: bs4,
      name: "Asic Bitmain Antminer S19 Pro 257T",
      price: "Price on Request",
      Desc: "http://minersrig.com/portal/index.php?rp=/store/bitmain-asic-miners/antminer-s19-pro-xp-hyd-257-th-s ",
    },
  ];
  const hotSalesArray = [
    {
      image: hs1,
      name: "Asic Bitmain Antminer S19 Pro Hydro 184T",
      price: "Price on Request",
      Desc: "http://minersrig.com/portal/index.php?rp=/store/bitmain-asic-miners",
    },
    {
      image: hs2,
      name: "ICERIVER KS0",
      price: "Price on Request",
      desc: "https://minersrig.com/portal/index.php?rp=/store/kaspa-miners",
    },
    {
      image: hs3,
      name: "ICERIVER KS3M 6T",
      price: "Price on Request",
      Desc: "https://minersrig.com/portal/index.php?rp=/store/kaspa-miners ",
    },
    {
      image: hs4,
      name: "JASMINER X16P 5800MH1900W",
      price: "Price on Request",
      Desc: "http://minersrig.com/portal/index.php?rp=/store/bitmain-asic-miners ",
    },
  ];
  const newArrivalsArray = [
    {
      image: na1,
      name: "Asic Bitmain Antminer S21 200T",
      price: "Price on Request",
      Desc: "http://minersrig.com/portal/index.php?rp=/store/bitmain-asic-miners ",
      indes: "preorder",
    },
    {
      image: na2,
      name: "Asic Whatsminer M30S++ 108T",
      price: "Price on Request",
      desc: "http://minersrig.com/portal/index.php?rp=/store/whats-miners/whatsminer-m30s-108-th-s",
    },
    {
      image: na3,
      name: "ICERIVER KS3 9.4T ",
      price: "Price on Request",
      Desc: "https://minersrig.com/portal/index.php?rp=/store/kaspa-miners",
    },
    {
      image: na4,
      name: "JASMINER X16Q 1950MH620W",
      price: "Price on Request",
      Desc: "http://minersrig.com/portal/index.php?rp=/store/bitmain-asic-miners ",
    },
  ];

  const handleSelected = (node) => {
    setSelected(node);
    if (node === "Best Sellers") {
      setDisplayArray(BestSellersArray);
    } else if (node === "New Arrivals") {
      setDisplayArray(newArrivalsArray);
    } else {
      setDisplayArray(hotSalesArray);
    }
  };

  console.log(displayArray, "displayArray");
  return (
    <div className="mt-20 md:px-20 px-5 flex flex-col gap-10">
      <ul className="flex justify-center items-center gap-10 text-2xl list-none cursor-pointer">
        <li
          onClick={() => handleSelected("Best Sellers")}
          className={`${
            selected === "Best Sellers"
              ? `font-bold`
              : `font-thin opacity-75 text-gray-500`
          }`}
        >
          Best Sellers
        </li>
        <li
          onClick={() => handleSelected("New Arrivals")}
          className={`${
            selected === "New Arrivals"
              ? `font-bold`
              : `font-thin opacity-75 text-gray-500`
          }`}
        >
          New Arrivals
        </li>
        <li
          onClick={() => handleSelected("Hot Sales")}
          className={`${
            selected === "Hot Sales"
              ? `font-bold`
              : `font-thin opacity-75 text-gray-500`
          }`}
        >
          {" "}
          Hot Sales
        </li>
      </ul>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 grid-rows-1 gap-5">
        {displayArray.map((items, index) => {
          return (
            <div
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              data-aos="flip-left"
              data-aos-delay={`${index === 0 ? 0 : index * 400}`}
            >
              <Cards
                index={index}
                image={items.image}
                name={items.name}
                price={items?.price}
                desc={items?.Desc}

                selected={selected}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BestSeller;


// import React, { useEffect, useState } from "react";
// import bs1 from "../assets/filecoin-pic.png";
// import bs2 from "../assets/Asic Whatsminer m30s 90th-2.png";
// import bs3 from "../assets/Asic Bitmain Antminer KA3 166th_3.png";
// import bs4 from "../assets/Asic Bitmain Antminer T19 hydro 145th_3.png";
// import hs1 from "../assets/hot sales/Asic Bitmain Antminer S19 XP hydro 250th_5.png";
// import hs2 from "../assets/hot sales/ICERIVER KAS KS0 3.png";
// import hs3 from "../assets/hot sales/ICERIVER KAS KS3M 4.png";
// import hs4 from "../assets/hot sales/JASMINER X16P 5800MH1900W-1.png";
// import na1 from "../assets/new arrivals/Asic Bitmain Antminer Z15 420ksol_3.png";
// import na2 from "../assets/new arrivals/Asic Whatsminer m30s 90th-3.png";
// import na3 from "../assets/new arrivals/ICERIVER KAS KS1 3.png";
// import na4 from "../assets/new arrivals/JASMINER X16Q 1950MH620W-2.png";
// import Cards from "./Cards";
// import { useNavigate } from "react-router-dom";

// const BestSeller = () => {
//   const navigate = useNavigate();
//   const [selected, setSelected] = useState("Best Sellers");
//   const [displayArray, setDisplayArray] = useState([]);

//   useEffect(() => {
//     setDisplayArray(BestSellersArray);
//   }, []);

//   const BestSellersArray = [
//     {
//       image: bs1,
//       name: "FileCoin Miner",
//       price: "Price on Request",
//       Desc: "/fileCoin",
//     },
//     {
//       image: bs2,
//       name: "Asic Whatsminer M50s 120T",
//       price: "Price on Request",
//       desc: "https://minersrig.com/portal/cart.php?a=confproduct&i=9",
//     },
//     {
//       image: bs3,
//       name: "Asic Whatsminer M30s 90T",
//       price: "Price on Request",
//       Desc: "http://minersrig.com/portal/index.php?rp=/store/whats-miners/whatsminer-m30s90th-s",
//     },
//     {
//       image: bs4,
//       name: "Asic Bitmain Antminer S19 Pro 257T",
//       price: "Price on Request",
//       Desc: "http://minersrig.com/portal/index.php?rp=/store/bitmain-asic-miners/antminer-s19-pro-xp-hyd-257-th-s ",
//     },
//   ];
//   const hotSalesArray = [
//     {
//       image: hs1,
//       name: "Asic Bitmain Antminer S19 Pro Hydro 184T",
//       price: "Price on Request",
//       Desc: "http://minersrig.com/portal/index.php?rp=/store/bitmain-asic-miners",
//     },
//     {
//       image: hs2,
//       name: "ICERIVER KS0",
//       price: "Price on Request",
//       desc: "https://minersrig.com/portal/index.php?rp=/store/kaspa-miners",
//     },
//     {
//       image: hs3,
//       name: "ICERIVER KS3M 6T",
//       price: "Price on Request",
//       Desc: "https://minersrig.com/portal/index.php?rp=/store/kaspa-miners ",
//     },
//     {
//       image: hs4,
//       name: "JASMINER X16P 5800MH1900W",
//       price: "Price on Request",
//       Desc: "http://minersrig.com/portal/index.php?rp=/store/bitmain-asic-miners ",
//     },
//   ];
//   const newArrivalsArray = [
//     {
//       image: na1,
//       name: "Asic Bitmain Antminer S21 200T",
//       price: "Price on Request",
//       Desc: "http://minersrig.com/portal/index.php?rp=/store/bitmain-asic-miners ",
//       indes: "preorder",
//     },
//     {
//       image: na2,
//       name: "Asic Whatsminer M30S++ 108T",
//       price: "Price on Request",
//       desc: "http://minersrig.com/portal/index.php?rp=/store/whats-miners/whatsminer-m30s-108-th-s",
//     },
//     {
//       image: na3,
//       name: "ICERIVER KS3 9.4T ",
//       price: "Price on Request",
//       Desc: "https://minersrig.com/portal/index.php?rp=/store/kaspa-miners",
//     },
//     {
//       image: na4,
//       name: "JASMINER X16Q 1950MH620W",
//       price: "Price on Request",
//       Desc: "http://minersrig.com/portal/index.php?rp=/store/bitmain-asic-miners ",
//     },
//   ];

//   const handleSelected = (node) => {
//     setSelected(node);
//     if (node === "Best Sellers") {
//       setDisplayArray(BestSellersArray);
//     } else if (node === "New Arrivals") {
//       setDisplayArray(newArrivalsArray);
//     } else {
//       setDisplayArray(hotSalesArray);
//     }
//   };

//   const handleCardClick = (desc) => {
//     // Use navigate to programmatically navigate to the specified path fileCoin
//     navigate(desc);
//   };
//   console.log(displayArray, "displayArray");

//   return (
//     <div className="mt-20 px-20 flex flex-col gap-10">
//       <ul className="flex justify-center items-center gap-10 text-2xl list-none cursor-pointer">
//         <li
//           onClick={() => handleSelected("Best Sellers")}
//           className={`${
//             selected === "Best Sellers"
//               ? `font-bold`
//               : `font-thin opacity-75 text-gray-500`
//           }`}
//         >
//           Best Sellers
//         </li>
//         <li
//           onClick={() => handleSelected("New Arrivals")}
//           className={`${
//             selected === "New Arrivals"
//               ? `font-bold`
//               : `font-thin opacity-75 text-gray-500`
//           }`}
//         >
//           New Arrivals
//         </li>
//         <li
//           onClick={() => handleSelected("Hot Sales")}
//           className={`${
//             selected === "Hot Sales"
//               ? `font-bold`
//               : `font-thin opacity-75 text-gray-500`
//           }`}
//         >
//           {" "}
//           Hot Sales
//         </li>
//       </ul>
//       <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 grid-rows-1">
//         {displayArray.map((items, index) => {
//           return (
//             <div
//               data-aos-easing="ease-out-cubic"
//               data-aos-duration="1000"
//               data-aos="flip-left"
//               data-aos-delay={`${index === 0 ? 0 : index * 400}`}
//             >
//               <Cards
//                 index={index}
//                 image={items.image}
//                 name={items.name}
//                 price={items?.price}
//                 desc={items?.Desc}
//                 selected={selected}
//                 onClick={() => handleCardClick(items?.Desc)}
//               />
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default BestSeller;
