import React, { useState } from "react";
import QA from "./QA";

const FAQ = () => {
  const faqArray = [
    {
      quest: "  1.What is Bitcoin mining hosting?",
      ans: " Bitcoin mining hosting refers to a service where individuals or  businesses can rent space in a data center specifically designed for  mining cryptocurrency. We, as hosting provider takes care of the   infrastructure, electricity, cooling, and maintenance, allowing miners to focus on their mining operations without the hassle of managing thehardware themselves.",
    },
    {
      quest: " 2.How does Bitcoin mining work?",
      ans: "Bitcoin mining is the process of validating transactions and adding them to theblockchain. Miners use specialized computer hardware to solve complex mathematicalpuzzles, and the first one to find a valid solution gets to add a new block to theblockchain. In return for their efforts, miners are rewarded with newly created bitcoinsand transaction fees.",
    },
    {
      quest: "  3.What types of mining machines do MINERSRIG sell?",
      ans: " Our hosting service provides several benefits over home mining. We offer a controlledand optimized environment for mining machines, ensuring efficient cooling and reduceddowntime. We have access to cost-effective electricity rates due to our scale, which cansignificantly increase your mining profitability. Additionally, you won't need to deal with thenoise, space, and maintenance issues associated with home mining.",
    },
    {
      quest:
        "4.Why should I choose the MINERSRIG hosting service instead of mining at home?",
      ans: "Our hosting service provides several benefits over home mining. We offer a controlledand optimized environment for mining machines, ensuring efficient cooling and reduceddowntime. We have access to cost-effective electricity rates due to our scale, which cansignificantly increase your mining profitability. Additionally, you won't need to deal withthe noise, space, and maintenance issues associated with home mining.",
    },
    {
      quest: "5.What are the hosting fees and payment options?",
      ans: "Our hosting fees depend on the specific hosting package you choose and the amount ofspace and power your mining machines require. We offer various payment options,including credit cards, bank transfers, and cryptocurrencies.",
    },
    {
      quest:
        " 6.Can I use my mining machines with the MINERSRIG hosting service?",
      ans: "Yes, we welcome customers who wish to use their mining machines. We'll provide thenecessary specifications and guidelines to ensure compatibility and optimal performance within our hosting facility.",
    },
    {
      quest:
        "7.What happens if my mining machine experiences a hardware failure?",
      ans: "In case of hardware failure, our technical team will promptly address the issue and carryout the necessary repairs or replacements. We understand the importance of keeping your mining equipment running smoothly to maximize your mining potential.",
    },
    {
      quest: " 8.Can I upgrade my hosting package as my mining needs grow? ",
      ans: "Yes, we understand that mining requirements may change over time. You can easilyupgrade your hosting package to accommodate additional mining machines or higher power requirements.",
    },
    {
      quest:
        " 9.Do you offer any special deals or discounts for long-term hosting contracts?",
      ans: "Yes, we have attractive offers and discounts for customers who sign up for long-termhosting contracts. These options are designed to provide additional value and cost savings for committed miners.",
    },
  ];
  return (
    <div className="flex flex-col items-center gap-5 font-nunito">
      <h1 className="font-extrabold text-4xl">FAQs</h1>
      {faqArray.map((node) => {
        return <QA ques={node?.quest} ans={node?.ans} />;
      })}
    </div>
  );
};

export default FAQ;
