import React from "react";
import section1 from "../assets/section1.png";
import section2 from "../assets/section2.png";
import section3 from "../assets/section3.png";
import "../App.css";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";

const Section = () => {
  const navigate = useNavigate();

  const handlelink = (node) => {
    // setShowNav(false);
    if (node === "Filecoin Miner") {
      navigate("/fileCoin");
    } else if (node === "Antiminer") {
      window.open(
        " http://minersrig.com/portal/index.php?rp=/store/bitmain-asic-miners"
      );
    } else if (node === "KAS MIner") {
      window.open(
        " http://minersrig.com/portal/index.php?rp=/store/kaspa-miners"
      );
    } else if (node === "Whatsminer") {
      window.open(
        "https://minersrig.com/portal/index.php?rp=/store/whats-miners"
      );
    } else {
      window.open(
        "http://minersrig.com/portal/index.php?rp=/store/accessories"
      );
    }
  };
  return (
    <div className="flex flex-col mt-10 gap-10 lg:px-20">
      <div className="grid lg:grid-cols-3 lg:grid-rows-1 grid-cols-1 grid-rows-1">
        <div className="flex md:flex-row-reverse  flex-col items-center justify-start xl:col-span-2 lg:col-span-3 md:gap-8">
          <img src={section1} alt="shirt" />
          <div className=" flex flex-col items-start font-nunito font-bold gap-4">
            <p className=" text-4xl ">
            WHATSMINER <br />
            M50S 120T
            </p>
            <button
              data-aos="fade-right"
              onClick={() => handlelink("Whatsminer")}
              className="bg-[#FFFFFF] w-fit px-10 py-4 font-bold  font-nunito md: text-black flex items-center border-[1px] border-black hover:bg-yellow-200 "
            >
              {" "}
              SHOP NOW
              <span className="ml-2">
                <EastIcon />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 lg:grid-rows-1 grid-cols-1 grid-rows-2 mx-10 gap-10 ">
        <div className="flex flex-col md:flex-row-reverse lg:flex-col items-center gap-8 ">
          <img src={section2} alt="specs" />
          <div className=" flex flex-col items-start font-nunito font-bold gap-4">
            <p className=" text-4xl ">
            ANTMINER S19j <br />
            Pro+ 120T
            </p>
            <button
              data-aos="fade-right"
              data-aos-delay="800"
              onClick={() => handlelink("Antiminer")}
              className="bg-[#FFFFFF] w-fit px-10 py-4 font-bold  font-nunito md: text-black flex items-center border-[1px] border-black hover:bg-yellow-200 "
            >
              {" "}
              SHOP NOW
              <span className="ml-2">
                <EastIcon />
              </span>
            </button>
          </div>
        </div>
        <div className="flex md:flex-row-reverse flex-col items-center justify-start lg:gap-8  gap-4 lg:col-span-4 xl:col-span-3">
          <img src={section3} alt="shoes" />
          <div className=" flex flex-col items-start font-nunito font-bold gap-4">
            <p className=" text-4xl ">
            ANTMINER  <br />
            S19 86T
            </p>
            <button
              data-aos="fade-right"
              data-aos-delay="400"
              onClick={() => handlelink("Whatsminer")}
              className="bg-[#FFFFFF] w-fit px-10 py-4 font-bold  font-nunito md: text-black flex items-center border-[1px] border-black hover:bg-yellow-200 "
            >
              {" "}
              SHOP NOW
              <span className="ml-2">
                <EastIcon />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
