// import React from "react";
// import filecoin from "../assets/filecoin-pic.png";
// import EastIcon from "@mui/icons-material/East";
// import step1 from "../assets/Comprehensive.png";
// import step2 from "../assets/Return.png";
// import step3 from "../assets/Significant.png";
// import step4 from "../assets/Extreme.png";
// import fileServer from "../assets/Fileserver (1).png";

// const FileCoinPage = () => {
//   const productDetails = [
//     {
//       image: filecoin,
//       tittle: "Hardware + 1st year hosting with no collateral",
//       name: "1 PiB FILECOIN MASTER",
//       price: "Price on Request",
//       more: "View More Details",
//     },
//     {
//       image: filecoin,
//       tittle: "Hardware + 1st year hosting with no collateral",
//       name: "2 PiB FILECOIN TITAN",
//       price: "Price on Request",
//       more: "View More Details",
//     },
//     {
//       image: filecoin,
//       tittle: "Hardware + 1st year hosting with no collateral",
//       name: "4 PiB FILECOIN POWERHOUSE",
//       price: "Price on Request",
//       more: "View More Details",
//     },
//   ];

//   const content = [
//     {
//       img: step1,
//       head: "Comprehensive Service withno Economic Barriers",
//       desc: "MinersRig provides High Quality FIL Mining Hardwares.MinersRig provides secure hosting and delegated staking",
//     },
//     {
//       img: step2,
//       head: "Return On Investment",
//       desc: "The Estimated payback period is 540 days with a coin price of $4.5/FIL.The default playback period for users with full staked coins delegated to miner",
//     },
//     {
//       img: step3,
//       head: "Significant and Accesible Assets",
//       desc: "Buyershave full ownership of the mining machines, Buyers can retrieve the mininghardware after hosting service ends ",
//     },
//     {
//       img: step4,
//       head: "Extreme Potential",
//       desc: "The Current FIL price is at a historical low indicating that there is tremendouspotential of price rise ",
//     },
//   ];
//   return (
//     <div className="flex flex-col justify-center items-center">
//       <div className="flex flex-col justify-center items-center font-mulish gap-10 border-2 p-5">
//         <p data-aos="fade-down" className=" font-normal text-5xl mt-4">
//           Our Filecoin Products
//         </p>
//         <div className="grid md:grid-cols-3 grid-cols-1 text-center ">
//           {productDetails.map((prod, index) => {
//             return (
//               <div
//                 data-aos="flip-up"
//                 data-aos-delay={`${index === 0 ? 0 : index * 400}`}
//                 className="flex flex-col items-center justify-center px-5 gap-6 font-medium"
//               >
//                 <img src={prod?.image} />
//                 <p>{prod?.tittle}</p>
//                 <p className="font-bold text-3xl">{prod?.name}</p>
//                 <p className="text-xl">{prod?.price}</p>
//                 <p>
//                   {prod?.more}
//                   <span className="ml-2">
//                     <EastIcon />
//                   </span>
//                 </p>
//               </div>
//             );
//           })}
//         </div>
//         <div className="grid md:grid-cols-2 md:grid-rows-2 grid-cols-1 grid-rows-1 mt-12">
//           {content.map((desc, index) => {
//             return (
//               <div
//                 data-aos="fade-right"
//                 data-aos-delay={`${index === 0 ? 0 : index * 300}`}
//                 className="flex flex-col gap-1 p-5 items-center justify-center font-normal text-lg text-center"
//               >
//                 <img src={desc.img} className="w-[40px] h-[40px]" />
//                 <p className="font-bold text-xl">{desc.head}</p>
//                 <p className="max-w-[560px]">{desc.desc}</p>
//               </div>
//             );
//           })}
//         </div>
//       </div>
//       <div className="flex flex-col items-center font-mulish gap-10 border-2 px-10 py-4 mt-4">
//         <div className="flex lg:flex-row flex-col-reverse text-white  items-center bg-black px-16 py-2 lg:py-0 rounded-[42px]">
//           <div className="text-center flex flex-col gap-6 max-w-md items-center">
//             <p className="font-black text-xl">
//               Decentralized Mining With MINERSRIG
//             </p>
//             <p>
//               Discover the revolution of decentralized storage by FILECOIN
//               MINING which empowers individuals and organizations to participate
//               in a decentralized,secure,and efficient global storage network,
//               while earning rewards for their contribution by minimizing the
//               risks with MINERSRIG
//             </p>
//             <button
//               data-aos="flip-right"
//               className="rounded-[42px] py-6 px-8 w-fit bg-white text-black"
//             >
//               More Details
//             </button>
//           </div>

//           <img
//             data-aos="zoom-in"
//             src={fileServer}
//             className="md:w-[850px] md:h-[750px]"
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FileCoinPage;

import React from "react";
import filecoin from "../assets/filecoin-pic.png";
import EastIcon from "@mui/icons-material/East";
import step1 from "../assets/step1.png";
import step2 from "../assets/step2.png";
import step3 from "../assets/step3.png";
import step4 from "../assets/step4.png";
import fileServer from "../assets/Fileserver (1).png";
const FileCoinPage = () => {
  const productDetails = [
    {
      image: filecoin,
      tittle: "Hardware + 1st year hosting with no collateral",
      name: "1 PiB FILECOIN MASTER",
      price: "Price on Request",
      more: "View More Details",
      link: "https://minersrig.com/portal/index.php?rp=/store/filecoin-miners",
    },
    {
      image: filecoin,
      tittle: "Hardware + 1st year hosting with no collateral",
      name: "2 PiB FILECOIN TITAN",
      price: "Price on Request",
      more: "View More Details",
      link: " http://minersrig.com/portal/index.php?rp=/store/filecoin-miners/2-pib-filecoin-titan",
    },
    {
      image: filecoin,
      tittle: "Hardware + 1st year hosting with no collateral",
      name: "4 PiB FILECOIN POWERHOUSE",
      price: "Price on Request",
      more: "View More Details",
      link: " http://minersrig.com/portal/index.php?rp=/store/filecoin-miners/4-pib-filecoin-powerhouse",
    },
  ];
  const content = [
    {
      img: step1,
      head: "Comprehensive Service withno Economic Barriers",
      desc: "MinersRig provides High Quality FIL Mining Hardwares.MinersRig provides secure hosting and delegated staking",
    },
    {
      img: step2,
      head: "Return On Investment",
      desc: "The Estimated payback period is 540 days with a coin price of $4.5/FIL.The default playback period for users with full staked coins delegated to miner",
    },
    {
      img: step3,
      head: "Significant and Accesible Assets",
      desc: "Buyershave full ownership of the mining machines, Buyers can retrieve the mininghardware after hosting service ends ",
    },
    {
      img: step4,
      head: "Extreme Potential",
      desc: "The Current FIL price is at a historical low indicating that there is tremendouspotential of price rise ",
    },
  ];
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center font-mulish gap-10 border-2 p-5">
        <p data-aos="fade-down" className=" font-normal text-5xl mt-4">
          Our Filecoin Products
        </p>
        <div className="grid md:grid-cols-3 grid-cols-1 text-center ">
          {productDetails.map((prod, index) => {
            return (
              <div
                data-aos="flip-up"
                data-aos-delay={`${index === 0 ? 0 : index * 400}`}
                className="flex flex-col items-center justify-center px-5 gap-6 font-medium"
              >
                <img src={prod?.image} />
                <p>{prod?.tittle}</p>
                <p className="font-bold text-3xl">{prod?.name}</p>
                <p className="text-xl">{prod?.price}</p>
                <a href={prod?.link}>
                  {prod?.more}
                  <span className="ml-2">
                    <EastIcon />
                  </span>
                </a>
              </div>
            );
          })}
        </div>
        <div className="grid md:grid-cols-2 md:grid-rows-2 grid-cols-1 grid-rows-1 mt-12">
          {content.map((desc, index) => {
            return (
              <div
                data-aos="fade-right"
                data-aos-delay={`${index === 0 ? 0 : index * 300}`}
                className="flex flex-col gap-1 p-5 items-center justify-center font-normal text-lg text-center"
              >
                <img src={desc.img} className="w-[40px] h-[40px]" />
                <p className="font-bold text-xl">{desc.head}</p>
                <p className="max-w-[560px]">{desc.desc}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col items-center font-mulish gap-10 border-2 px-10 py-4 mt-4">
        <div className="flex lg:flex-row flex-col-reverse text-white  items-center bg-black px-16 py-2 lg:py-0 rounded-[42px]">
          <div className="text-center flex flex-col gap-6 max-w-md items-center">
            <p className="font-black text-xl">
              Decentralized Mining With MINERSRIG
            </p>
            <p>
              Discover the revolution of decentralized storage by FILECOIN
              MINING which empowers individuals and organizations to participate
              in a decentralized,secure,and efficient global storage network,
              while earning rewards for their contribution by minimizing the
              risks with MINERSRIG
            </p>
            <button
              data-aos="flip-right"
              className="rounded-[42px] py-6 px-8 w-fit bg-white text-black"
            >
              More Details
            </button>
          </div>
          <img
            data-aos="zoom-in"
            src={fileServer}
            className="md:w-[850px] md:h-[750px]"
          />
        </div>
      </div>
    </div>
  );
};
export default FileCoinPage;