// import React, { useEffect } from "react";

// const Calculator = () => {
//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = "https://api.minerstat.com/v2/widgets/coin.js";
//     script.charset = "utf-8";
//     script.async = true;
//     document.head.appendChild(script);

//     return () => {
//       // Cleanup script when the component is unmounted
//       document.head.removeChild(script);
//     };
//   }, []);
//   const array = ["EXPLORE", "OUR", "HOSTING", "FACILITY", "NOW"];

//   return (
//     <div className="calcBg flex md:flex-row flex-col justify-around mt-20 md:mx-12 items-center bg-slate-100 md:py-4 py-10 h-fit gap-8 md:gap-0 text-black">
//       {/* First Column */}
//       <div className="px-0">
//         <a
//           className="minerstat-widget"
//           title="BTC mining calculator"
//           data-coin="BTC"
//           data-algo="SHA-256"
//           data-info="yes"
//           data-style="light"
//           data-color=""
//           data-unit="TH"
//           data-hashrate="100"
//           data-width="300"
//           rel="nofollow"
//           href="https://minerstat.com/coin/BTC"
//         >
//           Bitcoin mining calculator
//         </a>
//       </div>

//       {/* Second Column */}
//       <div className=" text-center flex flex-col md:gap-2 gap-1 ">
//         <div className="flex flex-row gap-4">
//           {array
//             .filter((item, ind) => {
//               return ind < 3;
//             })
//             .map((words, index) => {
//               console.log(index, "aro");
//               return (
//                 <a
//                   href="/host-miners"
//                   className={`${
//                     index !== 1 ? `text-black` : `text-black`
//                   } font-nunito font-black lg:text-5xl md:text-2xl text-xl`}
//                   data-aos="fade-left"
//                   data-aos-anchor-placement="center-center"
//                   data-aos-delay={`${index === 0 ? 100 : index * 400}`}
//                 >
//                   {words}
//                 </a>
//               );
//             })}
//         </div>
//         <div className="flex flex-row justify-center gap-4">
//           {array
//             .filter((item, ind) => {
//               return ind > 2;
//             })
//             .map((words, index) => {
//               console.log(index, "aroo");

//               return (
//                 <a
//                   href="/host-miners"
//                   className={`${
//                     index === 0 ? `text-black` : `text-black`
//                   } font-nunito font-black lg:text-5xl md:text-2xl text-xl`}
//                   data-aos="fade-left"
//                   data-aos-anchor-placement="center-center"
//                   data-aos-delay={`${index === 0 ? 1200 : 1600}`}
//                 >
//                   {words}
//                 </a>
//               );
//             })}
//         </div>
//         {/* Add more content as needed */}
//       </div>
//     </div>
//   );
// };

// export default Calculator;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Calculator = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://api.minerstat.com/v2/widgets/coin.js";
    script.charset = "utf-8";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      // Cleanup script when the component is unmounted
      document.head.removeChild(script);
    };
  }, []);

  const array = ["EXPLORE", "OUR", "HOSTING", "FACILITY", "NOW"];

  return (
    <div className="calcBg flex md:flex-row flex-col justify-around mt-20 md:mx-12 items-center bg-slate-100 md:py-4 py-10 h-fit gap-8 md:gap-0 text-black">
      {/* First Column */}
      <div className="px-0">
        <a
          className="minerstat-widget"
          title="BTC mining calculator"
          data-coin="BTC"
          data-algo="SHA-256"
          data-info="yes"
          data-style="light"
          data-color=""
          data-unit="TH"
          data-hashrate="100"
          data-width="300"
          rel="nofollow"
          href="https://minerstat.com/coin/BTC"
        >
          Bitcoin mining calculator
        </a>
      </div>

      {/* Second Column */}
      <div className=" text-center flex flex-col md:gap-2 gap-1 ">
        <div className="flex flex-row gap-4">
          {array
            .filter((item, ind) => ind < 3)
            .map((words, index) => (
              <Link
                to="/host-miners"
                className={`${
                  index !== 1 ? `text-black` : `text-black`
                } font-nunito font-black lg:text-5xl md:text-2xl text-xl`}
                data-aos="fade-left"
                data-aos-anchor-placement="center-center"
                data-aos-delay={`${index === 0 ? 100 : index * 400}`}
              >
                {words}
              </Link>
            ))}
        </div>
        <div className="flex flex-row justify-center gap-4">
          {array
            .filter((item, ind) => ind > 2)
            .map((words, index) => (
              <Link
                to="/host-miners"
                className={`${
                  index === 0 ? `text-black` : `text-black`
                } font-nunito font-black lg:text-5xl md:text-2xl text-xl`}
                data-aos="fade-left"
                data-aos-anchor-placement="center-center"
                data-aos-delay={`${index === 0 ? 1200 : 1600}`}
              >
                {words}
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Calculator;

