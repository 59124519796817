import React from "react";
import check from "../assets/service-center/CHECK .jpg";
import warranty from "../assets/service-center/1 YEAR WARRANTY.png";

const ServiceCenter = () => {
  return (
    <div className="flex flex-col items-center gap-10">
      <div className="bg-serviceHeader  h-[285px]  flex flex-col justify-center items-center w-screen">
        <p 
             data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"
             className="font-black text-white font-nunito md:text-9xl text-6xl text-center w-fit">
          REPAIR AND SERVICE
        </p>
      </div>
      <p className="px-8 max-w-4xl font-nunito text-center text-lg font-bold">
        We are team of highly experienced and ASIC-certified experts, available
        either by phone or in- person at our Dubai location, to address any
        repair or servicing requirements for your ASIC miners. Trust in our team
        to deliver professional and reliable service to keep your mining
        operations running smoothly.
      </p>
      <button
        data-aos="flip-left"
        className="bg-black text-white w-fit px-4 py-2 font-bold rounded-xl"
      >
          <a href="https://minersrig.com/portal/submitticket.php?step=2&deptid=1">
          {" "}
          Contact us
        </a>
      </button>
      <div className="grid md:grid-cols-2 md:grid-rows-2 grid-cols-1">
        <div className="flex flex-col justify-center items-center px-10 bg-slate-200 md:order-1 order-2 py-10 md:py-0 md:h-[65vh]">
          <div className="text-left font-sans text-black px-5">
            <p data-aos="fade-up" className="font-bold text-[40px]">
              MAINTENANCE FOR EVERY 2 MONTHS
            </p>
            <p data-aos="fade-up" className="max-w-5xl">
              If you've purchased Asic Miners through MINERSRIG, you'll receive
              a complimentary check-up every two months. We conduct routine
              maintenance checkups for all our customers, ensuring that your
              ASIC miners or GPU miners are operating at peak efficiency. This
              commitment provides you with the assurance of optimal performance
              for your mining equipment.
            </p>
          </div>
        </div>
        <div className=" imageStyle w-fit h-auto md:order-2 order-1">
          <img src={check} className="w-screen md:h-[65vh]" />
        </div>
        <div className="imageStyle w-fit h-fit order-3">
          <img src={warranty} className="w-screen md:h-[65vh]" />
        </div>
        <div className="flex flex-col justify-center items-center px-10 bg-slate-200 order-4 py-10 md:py-0 md:h-[65vh]">
          <div className="text-left font-sans text-black px-5">
            <p data-aos="fade-up" className="font-bold text-[40px]">
              1 YEAR WARRANTY
            </p>
            <p data-aos="fade-up" className="max-w-5xl">
              All our products include a 1-year warranty and 24/7 crypto mining
              consultation services. Should operational issues arise after the
              warranty period, our ASIC miner repair solutions will promptly
              address the issues, ensuring you can continue to profit from
              crypto mining without prolonged disruptions.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col  items-center gap-10 bg-black w-full text-white font-mulish  py-20">
      <div className="text-center font-sans ">
      <p className="font-bold md:text-4xl text-2xl text-center font-sans ">
      BITMAIN CERTIFIED TECHNICIANS
      </p><br/>
        <p className="font-base text-xl">If you've purchased ASIC miners through MINERSRIG, you're entitled to a complimentary checkup every two months. <br/>We conduct routine maintenance every two months for all our customers, ensuring that your ASIC miners operate at peak <br/> efficiency and providing you with the assurance of optimal performance.</p><br/>
        </div>  
    </div>
  
    <div className="flex flex-col items-center gap-10 bg-white w-full text-black font-mulish  py-20">
    <div className="text-center font-sans ">
    <p className="font-bold md:text-4xl text-2xl text-center font-sans ">
    Maintenance Services for All Types of ASIC Miners
    </p><br/>
      <p className="font-base text-xl">All our products are backed by a 1-year warranty, accompanied by round-the-clock crypto mining consultation services.<br/> Should operational issues arise beyond the warranty period, our ASIC miners repairing solutions will promptly address <br/>the issues, ensuring you can continue to profit from crypto mining without prolonged disruptions.
      </p><br/>
      </div>  
  </div>
    </div>
  );
};

export default ServiceCenter;
