import React, { useRef } from "react";
import videoBg from "../assets/Server Room Animation.mp4";
import "../App.css";
function Backgroundvideo() {
  return (
    <div className=" relative w-screen h-screen mt-20 ">
      <video
        src={videoBg}
        autoPlay
        loop
        muted
        className="w-full h-full object-cover"
      />
      <div className="centered-content">
        <h1 className="font-bold" style={{ fontSize: "3rem" }}>
          Join the World Top Miners
        </h1>
        <p className="text-center">
          Become one of the Thousands of Successful Mining Businesses
          <br /> Maximize your mining operation's potential and boost earnings
          with MINERSRIG
        </p>
        <br />
        <button
          data-aos="zoom-in-up"
          className="bg-[#fff] lg:px-10 lg:py-4 lg:font-bold md:px-6 md:py-2 px-3 py-1 md:font-medium font-nunito md: text-black flex items-center hover:bg-blue-500 hover:text-white "
        >
          <a href="https://minersrig.com/portal/index.php?rp=/login">
            Get Started Today
          </a>
        </button>
      </div>
    </div>
  );
}

export default Backgroundvideo;
