import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

const Cards = (props) => {
  const { image, name, price, desc, selected ,index} = props;
  console.log(selected, "selected");
  const navigate = useNavigate();

  const handleDetails = (node) => {
    console.log("hellooo", node);
    if (node === "filecoin") {
      navigate("/fileCoin");
    }
  };
  return (
    <div className="flex flex-col items-center gap-2 shadow-[rgba(50,50,93,0.25)_0px_6px_12px_-2px,_rgba(0,0,0,0.3)_0px_3px_7px_-3px] relative">
      <Tooltip
        title="Buy your Miner with UAE most trusted seller for more details and latest pricing contact us"
        arrow
      >
        <img src={image} alt="bs1" />
      </Tooltip>

      <div className="flex flex-col items-center font-bold font-nunito text-base cursor-pointer">
        <Tooltip
          title="Buy your Miner with UAE most trusted seller for more details and latest pricing contact us"
          arrow
        >
          {" "}
          <p className="text-xl text-center">{name}</p>
        </Tooltip>

        <p>{price}</p>
        {desc === "filecoin" ? (
          <a
            className="text-blue-500"
            href={desc}
            onClick={() => handleDetails(desc)}
          >
            {" "}
            view details
          </a>
        ) : (
          <a className="text-blue-500" href={desc}>
            {" "}
            view details
          </a>
        )}
      </div>

      {selected === "New Arrivals" & index == 0 ? (
        <button className="absolute top-0 left-0 py-2 px-4 bg-black rounded-lg text-white">
         Preorder
        </button>
      ) : null}
    </div>
  );
};

export default Cards;
