import React, { useState } from "react";
import step1 from "../assets/step1.png";
import step2 from "../assets/step2.png";
import step3 from "../assets/step3.png";
import step4 from "../assets/step4.png";
import Temparature from "../assets/min-img/temparature.png";

import whatsMiner from "../assets/WhatsMiner.png";
import HostCards from "./HostCards";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import complete from "../assets/min-img/FULL SETUP-min.jpg";
import remote from "../assets/min-img/REMOTE MONITORING-min.jpg";
import support from "../assets/min-img/24_7-min.jpg";

const HostMinerPage = () => {
  const gridElements = [
    {
      image: step1,
      name: "STEP 1",
      title: "BUY YOUR MINING MACHINE",
      desc: "Purchase your miner from the most reliable seller in the UAE. For the latest pricing and further details, please get in touch with us.",
    },
    {
      image: step2,
      name: "STEP 2",
      title: "YOUR MINERS ARE SHIPPED TO OUR HOSTING FACILITY",
      desc: "While your rigs are being shipped, complete your profile including adding your bitcoin wallet address and securing your account with 2FA.",
    },
    {
      image: step3,
      name: "STEP 3",
      title: "SETUP YOUR HOSTING PAYMENT",
      desc: "You can set up monthly payments or pay for the full year. These cover the monthly electricity bill and maintenance for your rigs. Now offering $0.047/kWh hosting.",
    },
    {
      image: step4,
      name: "STEP 4",
      title: "MINE & EARN PROFITS",
      desc: "You’re all set! Mine Bitcoin straight to your wallet and track payouts & performance. 85% of block rewards directly to your wallet, 15% to Sazmining. We don't earn if you don't earn.",
    },
  ];

  const [showMore, setShowMore] = useState(false);
  return (
  
    <div className="flex flex-col items-center gap-10">
      <div className="bg-hostHeader  h-[285px]  flex flex-col justify-center items-center w-screen">
        <p
          data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" 
          className="font-black text-white font-nunito md:text-9xl text-6xl text-center w-fit">
          MINER HOSTING
        </p>
      </div>

      <p className="px-8 max-w-4xl font-nunito text-lg font-light">
        Exploring cryptocurrency mining but facing challenges with space
        constraints or managing noisy equipment at home? Our adaptable hosting
        plans provide ASIC or GPU miners situated in top-tier facilities,
        enabling you to mine from the comfort of your home while enjoying
        substantial returns.
      </p>
      <button
        data-aos="flip-right"
        className="bg-black font-bold text-white w-fit px-4 py-2 rounded-xl"
      >
        {" "}
        <a href="https://minersrig.com/portal/submitticket.php?step=2&deptid=1">
          {" "}
          CONTACT US
        </a>
      </button>
      <div className="grid md:grid-cols-2 md:grid-rows-[0.5fr,0.5fr,0.5fr,0.5fr,0.5fr,0.5fr] grid-cols-1 grid-rows-[0.5fr ,1fr,0.5fr,1fr,1fr,1fr]">
        <div className="flex flex-col justify-center items-center px-10 bg-slate-200 py-10 md:py-0 md:order-1 order-2">
          <div className="text-left font-sans text-black px-5">
            <p data-aos="fade-up" className="font-bold text-[40px] uppercase">
              Complete Installation
            </p>
            <p data-aos="fade-up" className="max-w-5xl">
              From acquiring mining rigs to their installation, our host mining
              solutions handle every aspect for you. Our team of seasoned mining
              experts is always ready to assist you with any cryptocurrency
              mining inquiries or rig-related concerns you may encounter.
            </p>
          </div>
        </div>
        <div className="w-fit h-auto md:order-2 order-1  ">
          <img src={complete} className="imageStyle w-screen" />
        </div>
        <div className="w-fit h-auto order-3">
          <img src={remote} className=" imageStyle w-screen" />
        </div>
        <div className="flex flex-col justify-center items-center px-10 bg-slate-200 py-10 md:py-0 order-4">
          <div className="text-left font-sans text-black px-5">
            <p data-aos="fade-up" className="font-bold text-[40px] uppercase">
              REMOTE MONITORING
            </p>
            <p data-aos="fade-up" className="max-w-5xl">
              With our mobile reporting solutions, you can monitor your
              equipment remotely from any location. If market conditions render
              mining unprofitable, you have the flexibility to deactivate your
              rig and reactivate it when conditions become more favorable.
            </p>
          </div>
        </div>
        <div className="md:col-span-2 bg-white w-screen flex flex-col justify-center items-center text-black py-10 md:py-0 order-5 text-center">
          <p data-aos="fade-up" className="font-black text-[40px] uppercase">
            Direct Wallet Transfers
          </p>
          <p data-aos="fade-up" className="max-w-5xl text-center text-xl">
            All earnings generated from your mining activities are directly
            transferred to your chosen crypto wallet at regular intervals. This
            ensures you are free from concerns about intermediary fees,
            providing you with the flexibility to access and utilize your crypto
            mining earnings according to your preferences.
          </p>
        </div>
        <div className="flex flex-col justify-center items-center px-10 bg-slate-200 py-10 md:py-0 md:order-6 order-7">
          <div className="text-left font-sans text-black px-5">
            <p data-aos="fade-up" className="font-bold text-[40px] uppercase">
              24/7 SUPPORT
            </p>
            <p data-aos="fade-up" className="max-w-5xl">
              Access our 24/7 crypto mining consultation service for assistance
              with any mining-related queries or challenges. Our team of ASIC
              mining experts is readily available via phone to address
              operational issues, ensuring that your equipment operates
              seamlessly and yields maximum return on investment.
            </p>
          </div>
        </div>
        <div className="w-fit h-auto md:order-7 order-6">
          <img src={support} className=" imageStyle w-screen" />
        </div>
        <div className="w-fit h-auto order-8">
          <img src={Temparature} className=" imageStyle w-screen" />
        </div>
        <div className="flex flex-col justify-center items-center px-10 bg-slate-200 py-10 md:py-0 order-9">
          <div className="text-left font-sans text-black px-5">
            <p data-aos="fade-up" className="font-bold text-[40px] uppercase">
              Temperature, Dust, and Humidity Management
            </p>
            <p data-aos="fade-up" className="max-w-5xl">
              Our exclusive host mining facility, providing a stable environment
              for mining. Regular maintenance of ASIC and GPU miners is
              conducted to prevent technical or operational issues. Trust our
              optimized setting to keep your mining equipment running smoothly
              for as long as you require.
            </p>
          </div>
        </div>
  

        <div className="md:col-span-2 text-black w-screen flex flex-col justify-center items-center bg-white order-10">
          <p data-aos="fade-up" className="font-black text-[40px] uppercase">
            ALL IN ONE
          </p>
          <p data-aos="fade-up" className="max-w-5xl text-center text-xl">
            Experience comprehensive host mining plans that include setup,
            maintenance, electricity, and beyond, liberating you from the
            cumbersome responsibilities tied to ASIC and GPU mining. Contact us
            today for effortless and hassle-free host mining solutions, enabling
            you to earn money through crypto mining.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HostMinerPage;
