import { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./Components/Navbar";
import Aos from "aos";
import "aos/dist/aos.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FAQ from "./Components/FAQ";
import { Home } from "./Components/Home";
import HostMinerPage from "./Components/HostMinerPage";
import Footer from "./Components/footer";
import FileCoinPage from "./Components/FileCoinPage";
import ServiceCenter from "./Components/ServiceCenter";
function App() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      useClassNames: true,
      animatedClassName: "animated",
    });
    Aos.refresh();
  });

  const [dropDown, setDropDown] = useState(false);
  const [showNav, setShowNav] = useState(false);

  return (
    <div onClick={() => setDropDown(false)}>
      <Router>
        <Navbar
          dropDown={dropDown}
          setDropDown={setDropDown}
          showNav={showNav}
          setShowNav={setShowNav}
        />
        <Routes>
          <Route
            path="/"
            element={<Home showNav={showNav} setShowNav={setShowNav} />}
          />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/host-miners" element={<HostMinerPage />} />
          <Route path="/fileCoin" element={<FileCoinPage />} />
          <Route path="/serviceCenter" element={<ServiceCenter />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
