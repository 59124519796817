import React, { useEffect } from "react";
import video from "../assets/machine-gif.gif";
function Video() {
  useEffect(() => {
    // Your custom JavaScript code
    const customJavaScriptCode = `
// Your JavaScript code here
console.log("Hello from custom JavaScript code!");
`;
    // Include the CoinMarketCap widget script
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://files.coinmarketcap.com/static/widget/currency.js";
    script.async = true;
    // Add custom JavaScript code using script tags
    script.appendChild(document.createTextNode(customJavaScriptCode));
    // Append the script to the head of the document
    document.head.appendChild(script);
    // The widget div will be automatically rendered after the script loads
  }, []);
  console.log(window.innerWidth, "aswathy");
  return (
    <div className="grid md:grid-cols-3 grid-rows-1 text-white grid-cols-1 mt-20 mx-12 md:gap-2 gap-4 bg-black p-4 md:p-0 items-center shadow-[0px_10px_1px_rgba(221,_221,_221,_1),_0_10px_20px_rgba(204,_204,_204,_1)]">
      {/* First Column - Custom JavaScript Code and CoinMarketCap Widget */}
      <div className="flex flex-col justify-center sm:px-8">
        <div
          className="coinmarketcap-currency-widget"
          data-currencyid="1"
          data-base="USD"
          data-secondary=""
          data-ticker="true"
          data-rank="true"
          data-marketcap="true"
          data-volume="true"
          data-statsticker="true"
          data-stats="USD"
        ></div>
      </div>
      {/* Second Column - Video */}
      <div
        data-aos="zoom-in"
        data-aos-delay="300"
        className="flex flex-col items-center justify-center"
      >
        {/* Add your video component here */}
        <img width="70%" src={video} height="auto" />
      </div>
      {/* Third Column - Paragraph */}
      <div className="flex flex-col items-center justify-center p-8 w-fit gap-4 font-nunito ">
        {/* Add your paragraph here */}
        <p className="font-nunito lg:text-base md:text-sm text-lg text-justify text-white">
          Welcome to MINERSRIG, Your ultimate destination for all your Bitcoin mining
          requirements! As a leading provider of premium mining machines and
          top-notch hosting services,we are dedicated to assisting miners worldwide
          in succeeding in the dynamic realm of cryptocurrencies. Whether you're a 
          seasoned miner or embarking on your mining journey, we offer 
          everything you need to attain maximum profitability.
        </p>
        <button
          data-aos="flip-right"
          className="bg-[#F2BC01] lg:px-10 lg:py-4 lg:font-bold md:px-6 md:py-2 md:font-medium font-nunito md: text-white flex items-center hover:bg-white hover:text-black cursor-pointer "
        >
          <a href="https://minersrig.com/portal/submitticket.php?step=2&deptid=1">
            Contact us
          </a>
        </button>
      </div>
    </div>
  );
}

export default Video;
