import React, { useEffect, useState } from "react";
import Banner from "./Banner";
import Section from "./Section";
import BestSeller from "./BestSeller";
import Video from "./Video";
import Calculator from "./Calculator";
import Backgroundvideo from "./BackgroundVideo";

export const Home = (props) => {
  const { showNav, setShowNav } = props;

  return (
    <div
      onClick={() => {
        setShowNav(false);
      }}
    >
      <Banner />
      <Section />
      <BestSeller />
      <Video />
      <Calculator />
      <Backgroundvideo />
    </div>
  );
};
